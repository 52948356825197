// in src/students.js
import React from 'react';
// import TuitionReceiptButton from './TuitionReceiptButton';
// import AnnualReportButton from './AnnualReportButton';
// import RegReceiptButton from './RegReceiptButton';
// import MaterialReceiptButton from './MaterialReceiptButton';
// import LateFeeInvoiceButton from './LateFeeInvoiceButton';
// import GenericInvoiceButton from './GenericInvoiceButton';
// import CreateDraftEmailButton from './CreateDraftEmailButton';
// import SendWelcomeEmailButton from './SendWelcomeEmailButton';
import { SimpleList, RefreshButton, ExportButton, TopToolbar, ArrayInput, SimpleFormIterator, ArrayField, SingleFieldList, ChipField, List, Edit, Create, Datagrid, TextField, EditButton, LongTextInput, ReferenceInput, SelectInput, SimpleForm, TextInput, required, minLength, maxLength, minValue, maxValue, number, regex, email, choices, SelectField, AutocompleteInput } from 'react-admin';
import { Box, Typography, CardActions } from '@material-ui/core';
import FlatButton from '@material-ui/core/Button';
import Button from '@material-ui/core/Button';
import NavigationRefresh from '@material-ui/icons/Refresh';
import { RadioButtonGroupInput, Filter, ListButton, CreateButton, ShowButton, DeleteButton, BulkDeleteButton } from 'react-admin';
// import ExtraHrsButton from './ExtraHrsButton';
// import CashSummaryButton from './CashSummaryButton';
// import GenerateRCsButton from './GenerateRCsButton';
import { useMediaQuery } from '@material-ui/core';
// import StudentName from './studentName.js';
// import SignInButton from './SignInButton.js';
// import SignOutButton from './SignOutButton.js';
// import UpdateTRCommonData from './UpdateTRCommonData';
import StudentAbsence from './StudentAbsence.js';
import MyTab from './mytab';
import ActionsRefresh from './NoAction.js';
import useAnalyticsEventTracker from './analyticsTracker.js';




const validateName = [required(), minLength(2), maxLength(45)];
const validateEmail = [required(), email()];
const validatePIN = [required(), regex(/[0-9][0-9][0-9][0-9]/, "Must be 4 digit number")];
const validatePrg = [required()];
// const validateDate = [required(), regex(/[0-9][0-9]\/[0-9][0-9]\/[0-9][0-9][0-9][0-9]/, "Must be valid date mm/dd/yyyy")];
const validateDate = [regex(/[0-9][0-9]\/[0-9][0-9]\/[0-9][0-9][0-9][0-9]/, "Must be valid date mm/dd/yyyy")];
const validateDateOpt = [regex(/[0-9][0-9]\/[0-9][0-9]\/[0-9][0-9][0-9][0-9]/, "Must be valid date mm/dd/yyyy")];
const validateClass = [required()];
const validateSex = [required()];
const validateEStatus = [required()];


// console.log("Env:" + process.env.NODE_ENV)


const PDtls = ({ record }) => {
    var pnm = record.PName;
    var tel = record.Tel;
    return <span>{pnm}&nbsp;&nbsp;{tel}</span>
};

export const StudentsList = ({ permissions, ...props }) => {
    const isSmall = useMediaQuery(theme => theme.breakpoints.down('sm'));
    const gaEventTracker = useAnalyticsEventTracker('Students');
    gaEventTracker('Students List');
    if (permissions !== undefined && permissions.role !== null && permissions.role === 'user') {
        return (
            <List  {...props}  bulkActionButtons={false} sort={{ field: 'Sname', order: 'ASC' }} actions={<ActionsRefresh {...props} />} >
                {isSmall ? (
                    <Datagrid>
                        <TextField label="Student Name" source="Sname" />
                        <TextField label="Date of Joining" source="Sdoj" />
                        <TextField label="Program" source="Program" />
                        <TextField label="Use Of Media Approval" source="UseofMediaApproval" />
                        <TextField label="Tuition" source="Tuition" />
                        <TextField label="Discount" source="Discount" />
                        <TextField label="PIN" source="PIN" sortable={false} />
                        <StudentAbsence {...props} />
                    </Datagrid>
                ) : (
                    <Datagrid >
                        <TextField label="Student Name" source="Sname" />
                        <TextField label="Class" source="Classroom" />
                        <TextField label="Date of Joining" source="Sdoj" />
                        <TextField label="Enrollment Status" source="EStatus" />
                        <TextField label="Use Of Media Approval" source="UseofMediaApproval" />
                        <TextField label="Program" source="Program" />
                        <TextField label="Fee" source="Tuition" sortable={false} />
                        <TextField label="Discount" source="Discount" />
                        <TextField label="PIN" source="PIN" sortable={false} />
                        <ArrayField label="Reg Fee Paid" source="RegistrationFee" sortable={false}>
                            <SingleFieldList linkType={false}>
                                <ChipField source="RegYear" />
                            </SingleFieldList>
                        </ArrayField>
                        <StudentAbsence {...props} />
                    </Datagrid>)}
            </List>
        );
    }  else {
        return (<span>User has No Access...</span>)
    }
};

const StudentTitle = ({ record }) => {
    return <span>Student {record ? `"${record.id}"` : ''}</span>;
};




const cardActionStyle = {
    zIndex: 2,
    display: 'inline-block',
    float: 'right',
};


